import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const WaysTab = () => {
  return (
    <Container className="mt-4">
      <Row>
        <h1>
        Ways of Giving
        </h1>
        <Col md={6}>
          <p className="lead">
          Please get in touch with Arjun S Bartwal, Head of Fund Raising & Alumni Relations, The Doon School (dar@doonschool.com) if you have any questions in relation to making a donation
          </p>
        </Col>
        <Col md={6}>
          <p>
          Please remember to state the purpose of the donation to dar@doonschool.com and sharadrathke@gmail.com.
Bank Name: Lloyds Bank plc

          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={6}>
          <h5>
          Into the SCHOOL'S INDIAN Account<br/> (Currency: INR)
          </h5>
        </Col>
        <Col md={6}>
          <p>

          </p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>
          <p>
          (Tax rebate under section 80G of the IT Act, 1961 - Indian Citizens only)
          </p>
        </Col>
        <Col md={6}>
          <p>
          
          </p>
        </Col>
      </Row>

    

    </Container>
  );
};

export default WaysTab;
