import React, { useState, useEffect } from "react";
import "./index.scss";
import { ReactComponent as ScrollTopIcon } from "../../resources/icons/scrollTop.svg";

const ScrollTopButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);

    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);
  return (
    <div onClick={scrollToTop} style={{ display: visible ? "inline" : "none" }}>
      <div className="scroll-container">
        <ScrollTopIcon />
      </div>
    </div>
  );
};

export default ScrollTopButton;
