import { trackPromise } from "react-promise-tracker";
import { REST_URLS, HOSTNAME } from "./endpoints";

export const HTTP_METHODS = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
};
export const setToken = async () => {
  return fetch(`${HOSTNAME}${REST_URLS.GET_TOKEN}`, {
    method: "GET",
  });
};
const getToken = async () => {
  let tokens = localStorage.getItem("Token");
  if (tokens === "" || tokens == undefined) {
    setToken();
  } else {
    return tokens;
  }
};

export const invokeApi = async (method, url, data, params) => {
  let headers = {
    "Content-Type": "application/json",
    "Content-Length": Number.MAX_SAFE_INTEGER,
  };

  if (params) {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    url = url + "?" + query;
  }

  return trackPromise(
    fetch(url, {
      method: method,
      body: (data && JSON.stringify(data)) || undefined,
      headers: headers,
      params: params,
    }).then((response) => {
      if (response.status === 401) {
        return response.json();
        // window.location.reload(true);
      }

      if (response.status === 204) {
        return response;
      } else {
        return response.json();
      }
    })
  );
};
