export const pro = [
    { value: "Campus Development", label: "Campus Development" },
    { value: "scholarships", label: "Scholarships" },
    { value: "Batch Gifts", label: "Batch Gifts" },
    { value: "General Fund", label: "General Fund" },
  ];
  export const countryList = [
    { value: "india", label: "India" },
    { value: "america", label: "America" },
    { value: "canada", label: "Canada" },
    { value: "other", label: "Other" },
  ];
  