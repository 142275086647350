import React, { useEffect, useState } from "react";
import "./index.scss";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import SuccessImage from "../../resources/images/successImage.png";
import { Button, Grid } from "@mui/material";

const Success = () => {
  const history = useNavigate();
  const { txnId, id, amount } = useParams();
  return (
    <>
      <div className="container-success">
        <div className="card">
          <img src={SuccessImage} alt="success" />
          <div className="details">
            <h1>Payment Successful</h1>
            <p>Your payment has been processed!</p>
            <p>Details of transaction has been included below</p>
          </div>
          <div className="info-div">
            <Grid conatiner md={12} xs={12} className="grid-wrap">
              <Grid item md={6} xs={12}>
                <p>Transaction Id</p>
              </Grid>
              <Grid item md={6} xs={12}>
                <p>{txnId}</p>
              </Grid>
            </Grid>
            <Grid conatiner md={12} xs={12} className="grid-wrap">
              <Grid item md={6} xs={12}>
                <p>Amount</p>
              </Grid>
              <Grid item md={6} xs={12}>
                <p>₹{amount}</p>
              </Grid>
            </Grid>
          </div>
          <div className="btn-container">
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                history("/");
              }}
            >
              Go back Home
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Success;
