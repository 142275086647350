import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Grid } from "@mui/material";
import Card from "../../core/Card";
import { invokeApi } from "../../utils/http-service";
import { useNavigate } from "react-router-dom";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import "./schol.scss"
import Header from '../Header';
import Footer from '../Footer';

const ScholrTab = () => {
  const [BgColornew, setBgColornew] = useState();
  const [rednew, setBgColornew1] = useState();
  const [projList, setProjList] = useState([]);
  const history1 = useNavigate();
  const [bgColordon, setBgColordon] = useState();
  
  const getProjByCategory = (type) => {
    invokeApi("POST", `${HOSTNAME}${REST_URLS.LIST_PROJECT_BY_CRITERIA}`, {
      criteria: `(Project_Category:equals:${type})`,
    })
      .then((res) => {
        setProjList(res.data);
        if (!res.data) {
          toast.error(`Something Went Wrong ${res.message}`, {
            autoClose: 3000,
            toastId: "token expired",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      }); 
  };

  return (
    <div className='heihjhjd'>
    <div className='schoollist'>
<Header/>
{/* onClick={() => scholar('endschol')} */}
     <div className="container mt-5">
           <div className="row">
               <div className="col-md-3 mb-4">
<button className="buttsscholnew" type="button" >
  <button className="buttstylnew"
    onClick={() => getProjByCategory('Scholarship Endowments')}
  ><h1 className="text-center1"  style={{ background: BgColornew, color: rednew,width:"250px"  }}>Endowments</h1></button>
  </button>
               </div>
           
               <div className="col-md-3 mb-4 mr-3">
               <button className="buttsscholnew" type="button">
  <button className="buttstylnew"
  onClick={() => getProjByCategory('Scholarship Annuities')}
  
  ><h1 className="text-center1"  style={{ background: BgColornew, color: rednew,width:"250px" }}>Annuities</h1></button>
  </button>
               </div>
               
                   </div>  
  </div>
  <div className="listcontainer1" >
    {
      projList !== undefined &&
      projList.map((proj2) => {
        return (
          <div className="column" key={proj2.id}>
         
            <ul>
                <h3 className=" tilelist1">
                  <u className='ccmmleft' onClick={() => {
                      history1("/donation", {
                        state: { id: proj2.id },
                      });
                    }}>{(proj2.Name)}- {proj2 && proj2.Total_Amount
                    ? `Rs ${proj2.Total_Amount.toLocaleString('en-IN')}`
                    : 'TBC'}</u>
                </h3>
                <div className="text-right">
                  <span
                    className="read-more-line"
                    onClick={() => {
                      history1("/donation", {
                        state: { id: proj2.id },
                      });
                    }}
                  >
                  </span>
                
                </div>
              
            </ul>
      
          </div>
        );
      })
    }
  </div>
    
    </div>
    </div>
  );
};

export default ScholrTab;
