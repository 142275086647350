import React, { useState, useEffect } from "react";
import { ReactComponent as SearchIcon } from "../../resources/icons/search.svg";
import "./index.scss";
import Grid from "@mui/material/Grid";
import Logo from "../../resources/icons/logo.png";
import ContactTab from "../../components/Contact/Contact";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';


const Header = () => {

  return (
    <div>
    <div className="header-container ">
      <Grid container md={12} xs={12} className="logo-container">
        <Grid item md={4} xs={4}>
          <a href="https://www.doonschool.com/"><img src={Logo} alt="LOGO" className="logo" /></a>
        </Grid>
        <Grid item md={8} xs={8}>
          <div className="header-right ">
            <a href="/" className="home">
              <p>Home </p>
            </a>
            <div>
           <div class="dropdown ">
  <button class="dropbtn">Menu</button>
  <div class="dropdown-content ">

  
    <Link  to="/">Home</Link>
    <Link to="/contact">Contact</Link>
    <a href="https://www.doonschool.com/alumni/ways-of-giving/">Ways of Giving</a>
    <a href="https://www.doonschool.com/auditorium/index.php/bagse">Auditorium Seats</a>
    <Link to="/adddo">Donate Now</Link>
    {/* <Link to="/oth">Other Areas</Link> */}
   
  </div>
</div>
           </div>
            <div >
            <Link className="contact  si-hide-mobile" to="/contact">Contact</Link>
            </div>  
           <div className="waaa">
  <a className="Giving  si-hide-mobile " href="https://www.doonschool.com/alumni/ways-of-giving/">Ways of Giving</a>
</div>
           <div className="waaa">
  <a className="Giving1  si-hide-mobile" href="https://www.doonschool.com/auditorium/index.php/bagse">Auditorium Seats  </a>
</div>
<div className="waaa">
 <Link className="Giving home si-hide-mobile" to="/adddo">Donate Now</Link>
</div>
            {/* <div className="search-container si-hide-mobile">
              <SearchIcon />
            </div> */}
        
          </div>
          
        </Grid>
      </Grid>
  


    
        </div>
              
              </div>
  );
};

export default Header;
