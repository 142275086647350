import React from "react";
import "./index.scss";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
const ActiveNav = () => {
  return (
    <div style={{ maxWidth: "71em", margin: "auto" }}>
      <div>
        <nav className="nav-container">
          <ol>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <ChevronRightIcon
                style={{ color: "#5A3A31", fontWeight: "bolder" }}
              />
            </li>
            <li>Donation</li>
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default ActiveNav;
