import React, { useState, useEffect } from "react";
import "./index.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import Header from "../../core/Header";
import { Grid } from "@mui/material";
import Footer from "../../core/Footer";
import Countdown from "../Countdown";
import ScrollTopButton from "../ScrollToTopButton";
import DonationForm from "../DonationForm";
import ActiveNav from "../ActiveNav";
import { toast } from "react-toastify";
const Donation = () => {
  const location = useLocation();
  const history = useNavigate();
  const [projDetails, setProjDetails] = useState({});
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    if (location.state === null) {
      history("/");
    }
  });
  useEffect(() => {
    getProj();
  }, []);
  const getProj = () => {
    invokeApi("GET", `${HOSTNAME}${REST_URLS.LIST_PROJECTS}`)
      .then((res) =>
        res.data.forEach((ele) => {
          if (ele.id === location.state.id) {
            setProjDetails(ele);
            setPercent(() => {
              const result = (
                (ele.Amount_Received / ele.Total_Amount) *
                100
              ).toFixed(2);
              return result;
            });
          }
        })
      )

      .catch((err) => {
        console.log(err);
      });
  };
  const dateInMiliseconds = () => {
    let myDate = new Date(projDetails.Date_1);
    let result = myDate.getTime();
    return result;
  };

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Header />
        <div>
          <div className="bg-img">
            <div className="container">
              {/* <h1>Donation</h1> */}
            </div>
          </div>
          <ActiveNav />
          <section>
            <div className="detail-container">
              <Grid container md={12} xs={10} className="wrapper-container">
                <Grid item md={6} xs={8}>
                  <div className="sidebar-spacer">
                    <div className="leftSide-container">
                      <img
                        src={projDetails.Project_Image}
                        alt="img"
                        style={{ width: "100%", height: "350px" }}
                      />
                      <div className="content-wrap">
                        <div className="percentage-div">
                          <h3>
                            <span style={{ fontWeight: "bold" }}>
                              {/* {projDetails.$currency_symbol} */}
                              {/* {projDetails.Amount_Received}{" "} */}
                            </span>
                            <span style={{ fontWeight: "normal" }}>
                              {/* pledged of{" "} */}
                            </span>
                            <span>
                              {/* {projDetails.$currency_symbol} */}
                             
                            </span>
                          </h3>
                          {/* <h3>{percent}%</h3> */}
                        </div>
                        <div>
                          {/* <div className="skillbar-container"> */}
                            {/* <div
                              className="skillbar"
                              // style={{ width: `${percent}%` }}
                            ></div> */}
                          {/* </div> */}
                          {/* <Countdown */}
                            {/* // countdownTimestampMs={dateInMiliseconds()} */}
                          {/* /> */}
                        </div>
                      </div>
                      {/* <h2 className="nameCategory">{projDetails.Project_Category}</h2> */}
                      <div className="quote-con">
                        <div  style={{ maxWidth: "550px",color:"#d59b2d" }}>
                       
                          <p>
                          {projDetails.Description}</p>
                        <p>Amount: {projDetails.Total_Amount}</p>  
                          {/* <p>Amount:xxxxxx</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item md={5} xs={10}>
                  <div className="personal-details">
                    <h1 className="heading-main">
                      {/* <p className="small">Donation</p> */}
                      Personal Details
                    </h1>
                    <div className="donation-wrap">
                      <div className="row">
                        <Grid md={12} xs={10}>
                          <p className="desc">
                            This is simple a note to the donators regarding our
                            services and policy.
                          </p>
                          <div className="line-bottom"></div>
                        </Grid>
                        <DonationForm projectName={projDetails.Name} />
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </section>
          <ScrollTopButton />
        </div>
        <div className="si-hide-mobile">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Donation;
