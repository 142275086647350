import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Grid } from "@mui/material";
import Card from "../../core/Card";
import { invokeApi } from "../../utils/http-service";
import { useNavigate } from "react-router-dom";
import { HOSTNAME, REST_URLS,Finalte } from "../../utils/endpoints";
import { toast } from "react-toastify";
import "./comps.scss"  
import Header from '../Header';
import Footer from "../../core/Footer";



const CompTab = () => {
    // ... rest of your component code
    const [BgColornew, setBgColornew] = useState();
    const [rednew, setBgColornew1] = useState();
    const bgColorother = '#yourBackgroundColorValue';
    const redother = '#yourRedColorValue';
  const [projList, setProjList] = useState([]);
  const history = useNavigate();


  const getProjByCategory = (type) => {
    invokeApi("POST", `${HOSTNAME}${REST_URLS.LIST_PROJECT_BY_CRITERIA}`, {
      criteria: `(Project_Category:equals:${type})`,
    })
      .then((res) => {
        setProjList(res.data);
        if (!res.data) {
          toast.error(`Something Went Wrong ${res.message}`, {
            autoClose: 3000,
            toastId: "token expired",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const scholar = (end) => {
  }
  return (
 <div className='heihjhjd1'>
<Header/>
{/* <div className='image-with'>
<img
      src="TT.JPG"
      alt="Scholarship 2"
      className="img-fluid"
      style={{ width: '90%', height: '70vh' }}
    /> */}
{/* </div> */}

    {/* <div className='listcontainer125'>
      <div className="container mt-12 ">
      <div className="row"> */}
    
      {/* onClick={() => showcomp('scholarend1')} */}
          {/* <div className="col-md-3 mb-4 suuucon1">
          <button className="buttsscholnew" type="button" >
  <button className="buttstylnew"
    onClick={() => getProjByCategory('Classroom,Infrastructure')}
  ><h1 className="text-center1"  style={{ background: BgColornew, color: rednew,width:"250px"  }}>Infrastructure</h1></button>
  </button>
</div>
          <div className="col-md-3 mb-4 mr-3 suuucon1">
          <button className="buttsscholnew" type="button" >
  <button className="buttstylnew"
    onClick={() => getProjByCategory('Sports Facilities')}
  ><h1 className="text-center1"  style={{ background: BgColornew, color: rednew,width:"250px"  }}>Sports Facilities</h1></button>
  </button>
     
      
          </div>
          

              </div>     
      
</div> */}



  {/* <div className="listcontainer11">
    {
      projList !== undefined &&
      projList.map((proj2) => {
        return (
          <div className="column" key={proj2.id}>
            <ul>
              
         
                <h3 className="tilelist1">
                <img 
                            src={proj2.Project_Image}
                            alt="img"
                            className="image-size" style={{ width: '20%', height: '8vh', objectFit: 'fill'}}
                          />
                  <u className='ccmmleft' onClick={() => {
                      history("/donation", {
                        state: { id: proj2.id },
                      });
                    }}>{(proj2.Name)},{proj2 && proj2.Total_Amount
                      ? `Rs ${proj2.Total_Amount.toLocaleString('en-IN')}`
                      : 'TBC  '}</u>
                </h3>
                <div className="text-right">
                  <span
                    className="read-more-line"
                    onClick={() => {
                      history("/donation", {
                        state: { id: proj2.id },
                      });
                    }}
                  >
                    {/* <div className="line"></div>
                    <span>Read More</span> */}
                  {/* </span>
                </div>
              
            </ul>
          </div> */}
        {/* );
      })
    } */}
  {/* </div>  */}


   {/* </div> */}
      </div>


  );
};

export default CompTab;
