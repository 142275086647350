import React from 'react';
import "./contact.scss";
import Header from '../../core/Header';
import Footer from '../../core/Footer';

function ContactTab() {
  return (
    <> 
      <Header />
      <div className='contacttab container'>
        <img src="./hello.jpg" alt="My Image" className="img-fluid contactimage" style={{width:"100%",height:"450px"}} />
        {/* <p className='text1'>
          <span className='Address'>Address </span>- Fund Raising & Alumni Relations Office<br/>
          The Doon School, Mall Road, Dehradun, India - 248003
        </p> */}
        <div className='subaddress row'>
          <img src="./contact1.png" alt="contactimage" className="col-md-5 img-fluid subimgae" style={{width:"40%",height:"150px"}}/>
          <div className="col-md-6">
            <p>
              <span className="MR">Mr Arjun S Bartwal</span><br/>
              Head Of Fund Raising & Alumni Relations<br/>
              Email - <a href="mailto:dar@doonschool.com">dar@doonschool.com</a><br/>
              Phone - +91 135 2526555, <br/>+91 9953399509
            </p>
          </div>
        </div>
        <div className='subaddress1 row'>
          <img src="./contact2.png" alt="contactimage" className="col-md-5 img-fluid subimgae" style={{width:"35%",height:"150px"}} />
          <div className="col-md-7">
            <p>
              <span className="MR">Ms Anupama Rawat</span><br/>
              Coordinator – Fund Raising & Alumni Relations Office<br/>
              Email - <a href="mailto:fra.cord@doonschool.com">fra.cord@doonschool.com</a><br/>
              Phone - +91 135 2526550<br/>
              Fax: +91 135 2757275
            </p>
          </div>
        </div>
        <div className='contfooter'>
          <Footer/>
        </div>
    
      </div>

    </>
  );
}

export default ContactTab;
