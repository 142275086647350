import React from "react";
import "./index.scss";
import Header from "../../core/Header";
import TabContainer from "../TabContainer";
import ScrollTopButton from "../ScrollToTopButton";
import VideoPlayer from "../../core/Videoplay";
import Footer from "../../core/Footer";

const Home = () => {
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Header />
       
        <div>
          <div className="title-container">
          <VideoPlayer/>
      
            
            <div className="bottom-line"></div>
          </div>
   
          <TabContainer />
          <ScrollTopButton />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Home;
