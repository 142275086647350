import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Donation from "../components/Donation";
import Home from "../components/Home";
import Error from "../components/Error";
import Success from "../components/success";
import InvalidRoute from "../components/InvaildRoute";
import ContactTab from "../components/Contact/Contact";
import UpcomingProjects from "../core/upcomingprojects";
import CsrTab from "../core/CSR";
import LegaTab from "../core/legacy/leg";
import WaysTab from "../core/Wys/Ways";
import ScholrTab from "../core/school/school";
import CompTab from "../core/comps/comp";
import OtherTab from "../core/Other";
import Endwom from "../core/Endwo";
import Donationadd from "../core/Adddonation";


const AppRoutes = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/contact" element={<ContactTab/>}/>
          <Route exact path="/upcoming" element={<UpcomingProjects/>}/>
          <Route exact path="/csr" element={<CsrTab/>}/>
          <Route exact path="/way" element={<WaysTab/>}/>
          <Route exact path="/sch" element={<ScholrTab/>}/>
          <Route exact path="/comp" element={<CompTab/>}/>
          <Route exact path="/oth" element={<OtherTab/>}/>
          <Route exact path="/endw" element={<Endwom/>}/>
          <Route exact path="/adddo" element={<Donationadd/>}/>
          <Route exact path="/leg" element={<LegaTab/>}/>
          <Route exact path="/donation" element={<Donation />} />
          <Route exact path="/error/:id" element={<Error />} />
          {/* <Route
            exact
            path="/success/:id/:txnId/:amount"
            element={<Success />}
          /> */}
          <Route
            exact
            path="/success/:id/:txnId/:amount"
            element={<Success />}
          />
          <Route exact path="*" element={<InvalidRoute />} />
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;
