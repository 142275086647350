import React from 'react';
import "./csrstyle.scss"
import Header from '../Header';
import Footer from '../Footer';
const CsrTab = () => {
  return (
  <div>
           <Header />

            <div className='csrcont'> 
      
    <div className="container  mt-4 mx-auto">
      <div className="d-flex align-items-center mb-4">
        <div className="flex-shrink-0">
          <img src="./building.jpg" alt="Informational Image 1" className="img-fluid" style={{ width: '100px', height: '100px' }} />
        </div>
        <div className="flex-grow-1 ms-3">

          <p className="lead">
          To enhance our students' learning opportunities, the School<br/> proposes to upgrade the Library and its learning resources
          </p>
        </div>
      </div>
     
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0">
          <img src="./chemistry.jpg" alt="Informational Image 2" className="img-fluid" style={{ width: '100px', height: '100px' }} />
        </div>
        <div className="flex-grow-1 ms-3">

          <p className="lead">
          Having upgraded the Chemistry Labs, the School plans to upgrade <br/>the Physics and Biology Labs. 
          </p>

        </div>
      </div>
      <br/>
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0">
          <img src="./setusus.jpg" alt="Informational Image 2" className="img-fluid" style={{ width: '100px', height: '100px' }} />
        </div>
        <div className="flex-grow-1 ms-3">

          <p className="lead">
          The Wellness Centre requires a major overhaul to upgrade the <br/>facilities and increase capacity - with the addition of beds and <br/>dedicated treatment rooms. 

          </p>

        </div>
      </div>
      <br/>
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0">
          <img src="./house.jpg" alt="Informational Image 2" className="img-fluid" style={{ width: '100px', height: '100px' }} />
        </div>
        <div className="flex-grow-1 ms-3">

          <p className="lead">
          Foot House needs to be expanded to accommodate the extra Sc<br/> Leavers (1B) staying back through April and May. 
          </p>
        </div>
      </div>
      <br/>
   
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0">
          <img src="./burger.jpg" alt="Informational Image 2" className="img-fluid" style={{ width: '100px', height: '100px' }} />
        </div>
        <div className="flex-grow-1 ms-3">

          <p className="lead">
          The Tuck Shop is being envisioned to be transformed into a full-time<br/> Cafeteria, where not only will the boys get a chance to learn <br/>culinary and service skills, but also our parents & visitors will have a<br/> hospitable place to sit. 

          </p>
        </div>
      </div>
      <br/>
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0">
          <img src="./hoss.jpg" alt="Informational Image 2" className="img-fluid" style={{ width: '100px', height: '100px' }} />
        </div>
        <div className="flex-grow-1 ms-3">

          <p className="lead">
          The School wishes to advance the upgradation of <br/>accommodation for the Dames in the boarding houses. While <br/>basic upgrades have been undertaken time and again, the <br/>School is evaluating proposals to significantly enhance the<br/> living facilities for our Dames.

          </p>
        </div>
      </div>
    </div>
    </div>
  
    </div>
  );
};



export default CsrTab;
