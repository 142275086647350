import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const InvalidRoute = () => {
  const history = useNavigate();
  useEffect(() => {
    history("/");
  });
  return (
    <div>
      <h1>Page Not Found</h1>
    </div>
  );
};

export default InvalidRoute;
