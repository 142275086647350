// export const HOSTNAME =
//   "https://qkh5wv3k6d.execute-api.ap-south-1.amazonaws.com/prod";
export const HOSTNAME =
  "https://nav0v1rf8a.execute-api.ap-south-1.amazonaws.com/prod";
// export const HOSTNAME = "http://localhost:4000";
export const API_URL = "https://accounts.zoho.in/oauth/v2/token";
export const PAYU_TRANS_URL = "https://secure.payu.in/_payment";
export const PAYU_SUCCESS_URL = `${HOSTNAME}/v1/transaction/success/`;
export const PAYU_FAILED_URL = `${HOSTNAME}/v1/transaction/error/`;
export const PAYU_CANCEL_URL = `${HOSTNAME}/v1/transaction/error/`;
export const REST_URLS = {
  GET_TOKEN: "/v1/auth/getToken",
  LIST_PROJECTS: "/v1/project/list-projects",
  LIST_PROJECT_BY_CRITERIA: "/v1/project/list-project-by-criteria",
  PAYU_HASH: "/v1/project/hash",
  PAYU_TXNID: "/v1/project/txnid",
  PAYU_TRANSACTION: "/v1/transaction/transaction-info",
  GET_DONAR: "/v1/project/search-donar",
  SET_DONAR: "/v1/project/create-donar",
  ADD_DONAR_TRANSACTION: "/v1/transaction/user-transaction",
  UPDATE_DONOR: "/v1/project/update-donar/",
  VERIFY_PAYMENT: "/v1/transaction/verify-payment/",
  FAILED_PAYMENT: "/v1/transaction/error/",
  // Finalte:"v1/project?sort_order=asc&page=1&/list-project-by-criteria&per_page=200",
};
