import React from "react";
import "./index.scss";
import FacebookIcon from "../Face";
import Twitt from "../twit";
import Insta from "../insta";
import Youtu from "../youtube";
import { Link } from "react-router-dom";

const Footer = () => (
  <div className="fo">
    <footer className="page-section footer blk-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-2">
            <a href="https://www.doonschool.com" title="The Doon School">
              <img src="https://www.doonschool.com/wp-content/uploads/2018/03/ftr-logo.jpg" alt="Logo" />
            </a>
          </div>
          <div className="col-md-2">
            <div className="textwidget">
              <h5>Address</h5>
              <address>
                The Doon School, Mall Road, Dehradun, India, Pin-248003<br />
                Mobile:<a>+91-9953399509</a><br/>
                Direct Number – <a >+91-135-2526555/ 550</a><br />
                Email: <a href="mailto:dar@doonschool.com">dar@doonschool.com</a><br />
                
              </address>
            </div>
          </div>
          <div className="col-md-4">
    
            <ul className="inr-links horizontal-links">
              <li><a className="ahome" href="#" style={{ color: '#c5c3c3' ,textDecoration: 'none' }} >Home</a></li>
           
              <li><Link to="contact" className="ahome1"style={{ color: '#c5c3c3' ,textDecoration: 'none'}}>Contact Us</Link> </li>
            </ul>
            <div className="widget_text mt-3  news">
             <a href="https://www.doonschool.com/fund-raising-update/"  style={{ color: '#c5c3c3',textDecoration: 'none' }}><h5 className="Newsletter">Newsletter</h5></a>
              <div className="nws-form">
                <form method="post" action="https://www.doonschool.com/?na=s" onSubmit="return newsletter_check(this)">
                  <div className="form-group row">
                    <input className="form-control sendmail" type="email" name="ne" required="" />
                    <input className="col-md-4 col-lg-3 btn-sbmt sendmail1" type="submit" value="Submit" />
                  </div>
                </form>
              </div>
              <div className="social-icons">
              <a   style={{ color: '#c5c3c3' }}  href="https://www.facebook.com/thedoonschoolofficial/" className="facebook">
                <FacebookIcon />
              </a>
              <a href="https://twitter.com/The_Doon_School"  style={{ color: '#c5c3c3' }}  className="twiit">
                <Twitt />
              </a>
              <a href="https://www.instagram.com/thedoonschoolofficial/"  style={{ color: '#c5c3c3' }}  className="insta">
                <Insta />
              </a>
              <a href="https://www.youtube.com/@thedoonschoolofficial/"  style={{ color: '#c5c3c3' }}  className="you">
                <Youtu />
              </a>
            </div>
            </div>
          </div>
        </div>
        
      </div>
    </footer>
  </div>
);

export default Footer;
