import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./upindex.scss"
import Header from "../Header";

function UpcomingProjects(){
return(
  <div className="upcoming">


    <div className="upback">
        <Header/>
        <p className="t">The School received its CSR 1 certificate and is eligible to receive CSR Grants. The role of the School as an implementing agency has been vetted. With several areas that can be advanced though CSR donations. Our projects fall under the criteria of promotion of education and sport as per Schedule VII of the Companies Act.</p>
  
        </div>

  </div>
  
)





}
export default UpcomingProjects;