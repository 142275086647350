export const purposeList = [
  { value: "infrastructure", label: "Infrastructure" },
  // { value: "scholarship", label: "Scholarship" },
  { value: "sports facilities", label: "Sports Facilities" },
  { value: "other areas", label: "Other Areas" },
];
export const countryList = [
  { value: "india", label: "India" },
  { value: "america", label: "America" },
  { value: "canada", label: "Canada" },
  { value: "other", label: "Other" },
];
