import "./leg.scss"
import Header from "../Header";
import Footer from "../Footer";
function LegaTab(){
    return(
  <div>
        <Header/>
        <div className="legtest">

     
            <div className="container mt-4 ">
              
              <ol className="list-group mt-4">
              <li className="list-group-item">•The Legacy scheme is designed to be a relatively painless method by which each of us can include in our Wills what we would be able to allocate from our estate. Some useful features are:</li>
                <li className="list-group-item">•There is no compulsion of any kind and you may include whatever amount you wish.</li>
                <li className="list-group-item">•As we grow older, survive longer, we can continually modify the bequest as we see fit. As an example, an Old Boy in Chennai started with a particular figure, and after a few years has doubled it! God willing he is still hale and hearty today.</li>
                <li className="list-group-item">•From the form that you can download (attached with this email), you will see that there are multiple options that you can opt for, and I urge you to think of these possibilities.</li>
                <li className="list-group-item">•An aspect that you will be reminded of in the legacy form is that you must ensure that your heirs are aware of your bequest to School and signify their acceptance by signing the form.</li>
                <li className="list-group-item">•We are fortunate to have many lawyers and accountants among our alumni, and many of them have offered pro bono advice if any Old Boy has questions about the legacy process. All such requests must be routed via the Fund Raising and Alumni Relations at School. </li>
                <li className="list-group-item">•The Fund Raising and Alumni Relations
 will try to ensure that your legacy gift is completed smoothly, and also that you get suggestions of possible tax advantages depending on where you live </li>
              </ol>
            </div>
            </div>
          
            </div>
          );
        };
        

    export default LegaTab;