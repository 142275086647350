import React, { useEffect, useRef, useState } from "react";
import "./cd.scss";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Grid, colors } from "@mui/material";
import { pro, countryList } from "./configra";
import Header from "../Header";
import Footer from "../Footer";
import { invokeApi } from "../../utils/http-service";
import {
  HOSTNAME,
  REST_URLS,
  PAYU_TRANS_URL,
  PAYU_SUCCESS_URL,
  PAYU_FAILED_URL,
  PAYU_CANCEL_URL,
} from "../../utils/endpoints";
import { toast } from "react-toastify";
const Donationadd = ({ projectName }) => {
  const formRef = useRef();
  const [id, setId] = useState("");
  const [amount, setAmount] = useState("");
  const [zohoDonarId, setZohoDonarId] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    email: "",
    phoneNo: "",
    purposeOf: "",
    classOf: "",
    schoolNo: "",
    house: "",
    amount: "",
    panNo: "",
    country: "",
    pro:"",
    address: "",
  });
  const [hash, setHash] = useState("");
  const [txnid, setTxnid] = useState("");
  const [key, setKey] = useState("");
  const formSubmit = (e) => {
    e.preventDefault();
    if (parseInt(formData.amount) <= 10) {
      toast.error("Minimum Transaction Amount should be greater than Rs 10", {
        autoClose: 2000,
      });
      return;
    }
    if (
      formData.productinfo !== "" &&
      formData.title !== "" &&
      formData.firstName !== "" &&
      formData.email !== "" &&
      formData.phoneNo !== "" &&
      formData.classOf !== "" &&
      formData.schoolNo !== "" &&
      formData.house !== "" &&
      formData.panNo !== "" &&
      formData.country !== "" &&
      formData.address !== "" &&
      formData.amount !== "" &&
      formData.pro !==""
    ) {
      invokeApi("POST", `${HOSTNAME}${REST_URLS.PAYU_TXNID}`, {
        productinfo: formData.title,
        name: formData.firstName,
        house: formData.house,
        panNo: formData.panNo,
        address: formData.address,
        email: formData.email,
        classOf: formData.classOf,
        schoolNo: formData.schoolNo,
        amount: formData.amount,
        phoneNo: formData.phoneNo,
        purposeOf: formData.purposeOf,
        projectName:formData.pro,
        trigger: ["approval", "workflow", "blueprint"],
      })
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
            return;
          } else {
            if (
              res.address == formData.address &&
              res.amount == formData.amount &&
              res.classOf == formData.classOf &&
              res.email == formData.email &&
              res.firstName == formData.firstName &&
              res.house == formData.house &&
              res.panNo == formData.panNo &&
              res.phoneNo == formData.phoneNo &&
              res.purposeOf == formData.purposeOf &&
              res.schoolNo == formData.schoolNo &&
              res.title == formData.title
            ) {
              setId(res.id);
              setKey(res.key);
              setHash(res.hash);
              setTxnid(res.txnid);
              setAmount(res.amount);
            } else {
              toast.error("Something Went Wrong, Data Mismatch", {
                autoClose: 2000,
              });
              return;
            }
          }
        })
        .catch((err) => {
          toast.error("Failed to generate Transaction Id", {
            autoClose: 2000,
          });
        });
    } else {
      toast.error("Empty field not allowed", { autoClose: 2000 });
    }
  };
  useEffect(() => {
    if (formData.schoolNo !== "" && formData.classOf !== "" && id == "") {
      invokeApi("POST", `${HOSTNAME}${REST_URLS.GET_DONAR}`, {
        schoolNo: formData.schoolNo,
        year: formData.classOf,
      })
        .then((res) => {
          if (res.message) {
            setFormData((prevData) => ({
              ...prevData,
              email: "",
              firstName: "",
              address: "",
              country: "",
              phoneNo: "",
              house: "",
              panNo: "",
              amount: "",
              purposeOf: "",
              title: "",
              pro:"",
            }));
          }
          if (res.data) {
            if (res?.data[0]?.id) {
              setZohoDonarId(res.data[0].id);
            }
            setFormData((prevData) => ({
              ...prevData,
              email: res.data[0].Email,
              firstName: res.data[0].First_Name,
              address: res.data[0].Mailing_Street,
              country: res.data[0].Mailing_Country,
              pro:res.data[0].projectName,
              phoneNo: res.data[0].Mobile,
              house: res.data[0].House,
              panNo: res.data[0].PAN,
              title: res.data[0].Profession,
            }));
          }
        })
        .catch((err) => {
          setFormData((prevData) => ({
            ...prevData,
            email: "",
            firstName: "",
            address: "",
            country: "",
            phoneNo: "",
            house: "",
            panNo: "",
            purposeOf: "",
            title: "",
            pro:"",
          }));
        });
    }
  }, [formData.classOf, formData.schoolNo]);

  const createDonar = () => {
    invokeApi("POST", `${HOSTNAME}${REST_URLS.SET_DONAR}`, {
      id: id,
      data: [
        {
          Year: formData.classOf,
          Email: formData.email,
          First_Name: formData.firstName,
          Last_Name: formData.firstName,
          Mobile: formData.phoneNo,
          House: formData.house,
          PAN: formData.panNo,
          School_No: formData.schoolNo,
          Mailing_Country: formData.country,
          projectName:formData.pro,
          Mailing_Street: formData.address,
          Description: formData.purposeOf,
          Profession: formData.title,
        },
      ],
      trigger: ["approval", "workflow", "blueprint"],
    })
      .then((res) => {
        if (res.data[0].code === "SUCCESS") {
          formRef.current.click();
        } else {
          toast.error(`${res.data[0].code}, ${res.data[0].details.api_name}`, {
            autoClose: 2000,
          });
          setId("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateDonor = () => {
    if (zohoDonarId) {
      invokeApi("POST", `${HOSTNAME}${REST_URLS.UPDATE_DONOR}${zohoDonarId}`, {
        data: [
          {
            Email: formData.email,
            First_Name: formData.firstName,
            Last_Name: formData.firstName,
            Mobile: formData.phoneNo,
            House: formData.house,
            PAN: formData.panNo,
            Mailing_Country: formData.country,
            projectName:formData.pro,
            Mailing_Street: formData.address,
            Description: formData.purposeOf,
            Profession: formData.title,
          },
        ],
        trigger: ["approval", "workflow", "blueprint"],
      })
        .then((res) => {
          if (res.data[0].code === "SUCCESS") {
            formRef.current.click();
          } else {
            toast.error(
              `${res.data[0].code}, ${res.data[0]?.details?.api_name}`,
              {
                autoClose: 2000,
              }
            );
            setId("");
            setZohoDonarId("");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    if (id !== "") {
      invokeApi("POST", `${HOSTNAME}${REST_URLS.GET_DONAR}`, {
        schoolNo: formData.schoolNo,
        year: formData.classOf,
        id: id,
      })
        .then((res) => {
          if (res.message) {
            createDonar();
          }
          if (res.data) {
            updateDonor();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);
  return (
    <div className="addform" >
        <Header/>
        <div className="ddform"> 
      <form action={PAYU_TRANS_URL} method="post" className="formadd">
        <input type="hidden" name="firstname" value={formData.firstName}  />
        <input
          type="hidden"
          name="surl"
          value={`${PAYU_SUCCESS_URL}${id}/${txnid}/${amount}`}
        />
        <input type="hidden" name="phone"  style={{ backgroundColor: 'white' }} value={formData.phoneNo} />
        <input type="hidden" name="key" value={key} />
        <input type="hidden" name="hash" value={hash} />
        <input type="hidden" name="curl" value={`${PAYU_CANCEL_URL}${id}`} />
        <input type="hidden" name="furl" value={`${PAYU_FAILED_URL}${id}`} />
        <input type="hidden" name="txnid" value={txnid} />
        <input type="hidden" name="productinfo" value={formData.title} />
        <input type="hidden" name="amount" value={formData.amount} />
        <input type="hidden" name="email" value={formData.email} />
        <h3 className="school-info-title">School Information</h3>
        <Grid
          container
          md={12}
          xs={12}
          style={{
            display: "flex",
            // justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Grid item md={6} xs={12} style={{ maxWidth: "184px", marginLeft:"35px"}}>
            <TextField
              id="classOf"
              label="Class Of"
              required
              style={{ zIndex: 0,marginLeft:"2px" }}
              variant="outlined"
              onChange={(e) => {
                setFormData((prevVal) => ({
                  ...prevVal,
                  classOf: e.target.value,
                }));
                if (id !== "") {
                  setId("");
                }
              }}
            />
          </Grid>
          <Grid item md={6} xs={12} style={{ maxWidth: "185px",marginLeft:"35px"}}>
            <TextField
              id="schoolNo"
              label="School No."
              required
              style={{ zIndex: 0 ,marginLeft:"2px"}}
              variant="outlined"
              onChange={(e) => {
                setFormData((prevVal) => ({
                  ...prevVal,
                  schoolNo: e.target.value,
                }));
                if (id !== "") {
                  setId("");
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          md={12}
          xs={12}
          style={{
            display: "flex",
            // justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Grid item md={6} xs={12} style={{ maxWidth: "200px" ,marginLeft:"20px"}}>
            <TextField
              id="house"
              label="House"
              style={{ zIndex: 0 ,marginLeft:"15px" }}
              required
              variant="outlined"
              value={formData.house}
              onChange={(e) =>
                setFormData((prevVal) => ({
                  ...prevVal,
                  house: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item md={6} xs={12} style={{ maxWidth: "200px",marginLeft:"20px" }}>
            <TextField
              id="panNo"
              label="Pan No."
              required
              style={{ zIndex: 0 ,marginLeft:"15px"}}
              variant="outlined"
              value={formData.panNo}
              onChange={(e) =>
                setFormData((prevVal) => ({
                  ...prevVal,
                  panNo: e.target.value,
                }))
              }
            />
          </Grid>
        </Grid>
        <Grid
          container
          md={12}
          xs={12}
          style={{
            display: "flex",
            // justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Grid item md={6} xs={12} style={{ maxWidth: "185px" ,marginLeft:"35px"}}>
            <FormControl fullWidth>
              <InputLabel style={{ zIndex: 0}}>Country*</InputLabel>
              <Select
                labelId="Country"
                id="country"
                label="Country"
                value={formData.country}
                onChange={(e) => {
                  setFormData((prevVal) => ({
                    ...prevVal,
                    country: e.target.value,
                  }));
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {countryList.map((ele) => {
                  return (
                    <MenuItem value={ele.value} key={ele.value}>
                      {ele.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
         
          <Grid item md={6} xs={12} style={{ maxWidth: "200px" ,marginLeft:"20px"}}>
            <TextField
              id="address"
              label="Address"
              value={formData.address}
              required
              style={{ zIndex: 0 ,marginLeft:"15px" }}
              variant="outlined"
              onChange={(e) =>
                setFormData((prevVal) => ({
                  ...prevVal,
                  address: e.target.value,
                }))
              }
            />
          </Grid>
        </Grid>

        <Grid
          container
          md={12}
          xs={12}
          style={{
            display: "flex",
            // justifyContent: "space-between",
            marginBottom: 20,
            marginTop: 50,
          }}
        >
          <Grid item md={6} xs={12} style={{ maxWidth: "200px",marginLeft:"20px" }}>
            <TextField
              id="title"
              variant="outlined"
              label="Title"
              value={formData.title}
              required
              style={{ zIndex: 0 ,marginLeft:"15px" }}
              onChange={(e) => {
                setFormData((prevVal) => ({
                  ...prevVal,
                  title: e.target.value,
                }));
              }}
            />
          </Grid>
          <Grid item md={6} xs={12} style={{ maxWidth: "200px" ,marginLeft:"20px"}}>
            <TextField
              id="firstName"
              variant="outlined"
              label="First Name"
              value={formData.firstName}
              required
              style={{ zIndex: 0 ,marginLeft:"15px" }}
              onChange={(e) =>
                setFormData((prevVal) => ({
                  ...prevVal,
                  firstName: e.target.value,
                }))
              }
            />
          </Grid>
        </Grid>
        <Grid
          container
          md={12}
          xs={12}
          style={{
            display: "flex",
            // justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Grid item md={6} xs={12} style={{ maxWidth: "200px",marginLeft:"20px" }}>
            <TextField
              id="email"
              label="Email"
              value={formData.email}
              required
              style={{ zIndex: 0,marginLeft:"15px" }}
              variant="outlined"
              onChange={(e) =>
                setFormData((prevVal) => ({
                  ...prevVal,
                  email: e.target.value && e.target.value.toLowerCase(),
                }))
              }
            />
          </Grid>
          <Grid item md={6} xs={12} style={{ maxWidth: "200px" ,marginLeft:"20px"}}>
            <TextField
              id="phoneNo"
              type="number"
              label="Phone Number"
              required
              value={formData.phoneNo}
              style={{ zIndex: 0 ,marginLeft:"15px"}}
              variant="outlined"
              onChange={(e) =>
                setFormData((prevVal) => ({
                  ...prevVal,
                  phoneNo: e.target.value,
                }))
              }
            />
          </Grid>
        </Grid>
        <Grid
          container
          md={12}
          xs={12}
          style={{
            display: "flex",
            // justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Grid item md={6} xs={12} style={{ maxWidth: "185px",marginLeft:"35px" }}>
  <FormControl fullWidth>
    <InputLabel style={{ zIndex: 0,marginLeft:"20px" }}>Purpose*</InputLabel>
    <Select
      labelId="Purpose"
      id="purpose"
      label="Purpose"
      value={formData.pro} // Update to formData.pro
      onChange={(e) => {
        setFormData((prevVal) => ({
          ...prevVal,
          pro: e.target.value, // Update to pro
        }));
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        getContentAnchorEl: null,
      }}
    >
      {pro.map((ele) => ( 
        <MenuItem value={ele.value} key={ele.value}>
          {ele.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>
          <Grid item md={6} xs={12} style={{ maxWidth: "200px",marginLeft:"20px" }}>
            <TextField
              id="amount"
              type="number"
              label="Amount"
              required
              style={{ zIndex: 0 ,marginLeft:"10px" }}
              variant="outlined"
              onChange={(e) =>
                setFormData((prevVal) => ({
                  ...prevVal,
                  amount: e.target.value,
                }))
              }
            />
          </Grid>
        </Grid>

        <input
          type="submit"
          ref={formRef}
          value="submit"
          style={{ display: "none" }}
        />
        <input
          type="button"
          onClick={formSubmit}
          value=" Make A Payment"
          className="btn"
        />
      </form>
    </div>
    <div className="addfooterdona">
        <Footer/>
    </div>
    </div>
   
  );
};

export default Donationadd;
