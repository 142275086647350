import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Grid } from "@mui/material";
import Card from "../../core/Card";
import { invokeApi } from "../../utils/http-service";
import { useNavigate } from "react-router-dom";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import Header from '../Header';
import Footer from '../Footer';
import "./styele.scss"


const Endwom = ({ customFunction }) => {
    // ... rest of your component code
    const bgColorother = '#yourBackgroundColorValue';
    const redother = '#yourRedColorValue';
  const [projList1, setProjList] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    // Function to be called on page load
    getProjByCategory('Scholarship Endowments,Endowments');
  }, []); // The empty dependency array ensures that the effect runs only once on mount

  const getProjByCategory = (type) => {
    invokeApi("POST", `${HOSTNAME}${REST_URLS.LIST_PROJECT_BY_CRITERIA}`, {
      criteria: `(Project_Category:equals:${type})`,
    })
      .then((res) => {
        setProjList(res.data);
        if (!res.data) {
          toast.error(`Something Went Wrong ${res.message}`, {
            autoClose: 3000,
            toastId: "token expired",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className='enddd'>
    
<Header/>


<div className='image-with1 endim'>
<img
      src="TT.JPG"
      alt="Scholarship 2"
      className="img-fluid"
      style={{ width: '100%', height: '50vh' }}
    />
</div>

<div className="listcont enddpa" >
    {
      projList1 !== undefined &&
      projList1.map((proj2) => {
        return (
          <div className="column" key={proj2.id}>
          
            <ul>
                <h3 className=" tilelist1">
                  <u className='ccmmleft' onClick={() => {
                      history("/donation", {
                        state: { id: proj2.id },
                      });
                    }}>{(proj2.Name)}- {proj2 && proj2.Total_Amount
                    ? `Rs ${proj2.Total_Amount.toLocaleString('en-IN')}`
                    : 'TBC'}</u>
                </h3>
                <div className="text-right">
                  <span
                    className="read-more-line"
                    onClick={() => {
                        history("/donation", {
                        state: { id: proj2.id },
                      });
                    }}
                  >
                  </span>
                
                </div>
              
            </ul>
      
          </div>
        );
      })
    }
  </div>
<div id="footend">
<Footer/>
</div>

 

  </div>
 
  )};

export default Endwom;
