import "./styles.scss";
import React from 'react';
<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />

function VideoPlayer() {
  return (
    <div className="container-video">
      <div className="row">
        <div className="col-lg-12 mx-auto video-container">
          <video id="myvideo" autoPlay loop muted playsInline>
            {/* Add playsInline to allow full-screen video on iOS */}
            <source src="./video-2.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="col-lg-12 cdcll">
          <p className="lead634">
            The Doon School system of education promotes holistic development. Since 1935, the diversity in School has
            been a microcosm of India. To further this, we aspire to be a needs-blind institution that attracts the
            country's most talented boys, irrespective of their economic, social, and geographical backgrounds. For the
            School to be counted among the world's finest educational institutions, we need your support. Here is how
            you can support the School.
          </p>
        </div>
      </div>
    </div>
  );
}

export default VideoPlayer;
