import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Grid } from "@mui/material";
import Card from "../../core/Card";
import { invokeApi } from "../../utils/http-service";
import { useNavigate } from "react-router-dom";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import Header from '../Header';
import Footer from '../Footer';
import "./other.scss"

const OtherTab = ({ customFunction }) => {
    // ... rest of your component code
    const bgColorother = '#yourBackgroundColorValue';
    const redother = '#yourRedColorValue';
  const [projList, setProjList] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    // Function to be called on page load
    getProjByCategory("Other Areas");
  }, []); // The empty dependency array ensures that the effect runs only once on mount

  const getProjByCategory = (type) => {
    invokeApi("POST", `${HOSTNAME}${REST_URLS.LIST_PROJECT_BY_CRITERIA}`, {
      criteria: `(Project_Category:equals:${type})`,
    })
      .then((res) => {
        setProjList(res.data);
        if (!res.data) {
          toast.error(`Something Went Wrong ${res.message}`, {
            autoClose: 3000,
            toastId: "token expired",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const toProperCase = (text) => {
  //   return text
  //     .split(' ')
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  //     .join(' ');
  // };

  // Rest of your component code...

  return (
    <div>
    
<Header/>

<div className='othermanin'>
      <div className="main-card-container">
        <Grid container spacing={3}>
          {projList.map((proj1) => (
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              key={proj1.id}
            >
              <Card>
                <div>
                  <div
                    className="img"
                    onClick={() => {
                      history(`/donation`, {
                        state: { id: proj1.id },
                      });
                    }}
                  >
                    <div>
                      <img
                        src={proj1.Project_Image}
                        alt="img"
                        className="image-size"
                      />
                    </div>
                  </div>
                  <div className="text">
                    <p className="tag">
                      {/* <span>{proj1.Project_Category}</span> */}
                    </p>
                    <h3 className="head3">{(proj1.Name)}</h3>
                    <div className="text-right">
                      <span
                        className="read-more-line"
                        onClick={() => {
                          history("/donation", {
                            state: { id: proj1.id },
                          });
                        }}
                      >
                        <div className="line"></div>
                        <span>Read More</span>
                      </span>
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    
    </div>
    </div>
  );
};

export default OtherTab;
