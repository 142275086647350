import React, { useEffect } from "react";
import "./index.scss";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import failedImage from "../../resources/images/failed.png";

const Error = () => {
  const history = useNavigate();
  return (
    <div className="err-container">
      <div className="err-card">
        <img src={failedImage} alt="Failed" className="image" />
        <div className="info">
          <h1>Oh no! Your Payment Failed</h1>
          <p>Unable to process the your payment.</p>
          <p>Please Try Again</p>
        </div>
        <div className="error-btn">
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              history("/");
            }}
          >
            Try Again
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Error;
