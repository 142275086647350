import React, { useState, useEffect } from "react";
import "./index.scss";
import { getRemainingTimeUntilMsTimestamp } from "./countdown.helper";
import { Grid } from "@mui/material";

const Countdown = ({ countdownTimestampMs }) => {
  const [remainingTime, setRemainingTime] = useState({
    seconds: "00",
    minutes: "00",
    hours: "00",
    days: "00",
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(countdownTimestampMs);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countdownTimestampMs]);

  function updateRemainingTime(countdown) {
    setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
  }

  return (
    <div>
      <Grid container md={12} xs={12}>
        <Grid md={6} xs={12}>
          <ul className="countdown-timer">
            <li>
              <span>{remainingTime.days}</span>
              <div>Days</div>
            </li>
            <li>
              <span>{remainingTime.hours}</span>
              <div>Hours</div>
            </li>
            <li>
              <span>{remainingTime.minutes}</span>
              <div>Minutes</div>
            </li>
            <li>
              <span>{remainingTime.seconds}</span>
              <div>Seconds</div>
            </li>
          </ul>
        </Grid>
      </Grid>

      <span className="two-numbers"></span>
      <span></span>
      <span className="two-numbers"></span>
      <span></span>
      <span className="two-numbers"></span>
      <span></span>
    </div>
  );
};

export default Countdown;
