import React, { useState, useEffect } from "react";
import "./index.scss";


import 'bootstrap/dist/css/bootstrap.min.css';

import { Grid } from "@mui/material";
import { invokeApi } from "../../utils/http-service";
import { useNavigate } from "react-router-dom";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import Card from "../../core/Card";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Girl } from "@mui/icons-material";



const getNumberFromString = (str) => Number(str?.split("")?.filter(char => !Number.isNaN(Number(char))).join(""))



const TabContainer = () => {
  function processList(arr, order, status) {
    if(status === "annuities") {
      const ipss = arr.filter(item => item.Name.includes("IPSS")).sort((a, b) => getNumberFromString(a.Name) - getNumberFromString(b.Name))
      const year1 = arr.filter(item => item.Name.includes("1 Year")).sort((a, b) => getNumberFromString(a.Name) - getNumberFromString(b.Name))
      const year6 = arr.filter(item => item.Name.includes("6 Year")).sort((b, a) => getNumberFromString(a.Name) - getNumberFromString(b.Name))
      console.log(year6)
      console.log(getNumberFromString(year1[0]?.Name), year6[0]?.Name)
      if(order == 1) return [...ipss, ...year1]
      if(order == 2) return year6

    }

    const trophies = arr.filter(item => item.Name.includes("Trophy")).sort((a, b) => getNumberFromString(a.Name) - getNumberFromString(b.Name))
    const scholarships = arr.filter(item => !item.Name.includes("Trophy")).sort((b, a) => getNumberFromString(a.Name) - getNumberFromString(b.Name))
    console.log(trophies, scholarships, arr)
    console.log(getNumberFromString(trophies[0]?.Name), trophies[0]?.Name)
    if(order == 2) return trophies
    if(order == 1) return scholarships
  }

  const [projList1, setProjList1] = useState([]);
  // const history1 = useNavigate();
  useEffect(() => {
    getAllProjList1();
  }, []);
  const getAllProjList1 = () => {
    invokeApi("GET", `${HOSTNAME}${REST_URLS.LIST_PROJECTS}`)
      .then((res) => {
        setProjList1(res.data);
        if (!res.data) {
          toast.error(`Something Went Wrong ${res.message}`, {
            autoClose: 3000,
            toastId: "token expired",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getProjBy = (type) => {
    invokeApi("POST", `${HOSTNAME}${REST_URLS.LIST_PROJECT_BY_CRITERIA}`, {
      criteria: `(Project_Category:equals:${type})`,
    })
      .then((res) => {
        setProjList1(res.data);
        if (!res.data) {
          toast.error(`Something Went Wrong ${res.message}`, {
            autoClose: 3000,
            toastId: "token expired",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [projList, setProjList] = useState([]);
  const history = useNavigate();
  useEffect(() => {
    getAllProjList();
  }, []);
  const getAllProjList = () => {
    invokeApi("GET", `${HOSTNAME}${REST_URLS.LIST_PROJECTS}`)
      .then((res) => {
        setProjList(res.data);
        if (!res.data) {
          toast.error(`Something Went Wrong ${res.message}`, {
            autoClose: 3000,
            toastId: "token expired",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProjByCategory = (type) => {
    invokeApi("POST", `${HOSTNAME}${REST_URLS.LIST_PROJECT_BY_CRITERIA}`, {
      criteria: `(Project_Category:equals:${type})`,
    })
      .then((res) => {
        setProjList(res.data);
        if (!res.data) {
          toast.error(`Something Went Wrong ${res.message}`, {
            autoClose: 3000,
            toastId: "token expired",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const historycom = useNavigate();
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState(null)
  const [show3, setShow3] = useState(false);
  const [show2, setShow2] = useState(false);
  const [subshow, subcont1] = useState(false);
  const [subscom, subccom] = useState(false);
  const [show5, subccom5] = useState(false);
  const [comm, commp] = useState(false);
  const [comm1, commp1] = useState(false);

  const [BgColornew, setBgColornew] = useState();
  const [rednew, setBgColornew1] = useState();
  const [redcam, setBgColorScholarship2] = useState();
  const [reddonex, setBgColorextra] = useState();
  const [reddonex1, setBgColorextra1] = useState();
  const [redother, setBgColorScholarship4] = useState();
  const [bgColorCampus, setBgColorCampus] = useState();
  const [bgColordon, setBgColordon] = useState();
  const [bgColorother, setBgColorother] = useState();
  const [bgColorotherend, setBgColoroend] = useState();
  const [bgredend, setBgColoroend1] = useState();
  const [bgColoroani, setBgColorani] = useState();
  const [bgColoroani1, setBgColorani1] = useState();
  const [bgColoroa, setBgColora] = useState();
  const [bgColoroani2, setBgColorani2] = useState();
  const [bgColorupcoming, setBgColorupcoming] = useState();
  const [bgColorupcoming1, setBgColorupcoming1] = useState();
  const changeColor = (category) => {
    if (category === 'Scholarship') {
      // setBgColorScholarship((prevColor) => (prevColor === '#ffc800' ? '#FFFFFF' : '#FFFFFF'));
      setBgColordon((prevColor) => (prevColor === '#ffc800'));
      setBgColorCampus((prevColor) => (prevColor === '#A020F0'));
      setBgColorother((prevColor) => (prevColor === '#ffc800'));
      // setBgColorScholarship1((prevColor)=>(prevColor === '#ffc800' ? '#000000' : '#000000'));
      setBgColorScholarship2((prevColor) => (prevColor === '#ffc800'));
      // setBgColorScholarship3((prevColor)=> (prevColor === '#ffc800' ));
      setBgColorScholarship4((prevColor) => (prevColor === '#ffc800'));
      // subccom(false);
      commp(false);
      commp1(false);
      subccom5(false);
    } if (category === 'Campus') {
      setBgColorCampus((prevColor) => (prevColor === '#ffc800' ? '#FFFFFF' : '#FFFFFF'));
      // setBgColorScholarship((prevColor) => (prevColor === '#ffc800'));
      setBgColordon((prevColor) => (prevColor === '#ffc800'));
      setBgColorother((prevColor) => (prevColor === '#ffc800'));
      // setBgColorScholarship1((prevColor)=>(prevColor === '#ffc800'));
      setBgColorScholarship2((prevColor) => (prevColor === '#ffc800' ? '#000000' : '#000000'));
      // setBgColorScholarship3((prevColor)=> (prevColor === '#ffc800' ));
      setBgColorScholarship4((prevColor) => (prevColor === '#ffc800'));
      setBgColorextra((prevColor) => (prevColor === '#ffc800'));
      setBgColorextra1((prevColor) => (prevColor === '#ffc800'));
      subcont1(false);
      // subccom(false);
      commp(false);
      commp1(false);
      subccom5(false);
    }
    if (category === 'donor') {
      setBgColordon((prevColor) => (prevColor === '#ffc800' ? '#FFFFFF' : '#FFFFFF'));
      // setBgColorScholarship((prevColor) => (prevColor === '#ffc800'));
      setBgColorCampus((prevColor) => (prevColor === '#A020F0'));
      // setBgColorScholarship1((prevColor)=>(prevColor === '#ffc800'));
      setBgColorScholarship2((prevColor) => (prevColor === '#ffc800'));
      setBgColorScholarship4((prevColor) => (prevColor === '#ffc800'));
      // setBgColorScholarship3((prevColor)=> (prevColor === '#ffc800' ? '#000000' : '#000000'));

    }
    if (category === 'other') {
      setBgColorother((prevColor) => (prevColor === '#ffc800' ? '#FFFFFF' : '#FFFFFF'));
      setBgColornew((prevColor) => (prevColor === '#ffc800'));
      setBgColornew1((prevColor) => (prevColor === '#ffc800'));
      setBgColorScholarship4((prevColor) => (prevColor === '#ffc800' ? '#000000' : '#000000'));
      setBgColorCampus((prevColor) => (prevColor === '#ffc800'));
      setBgColorScholarship2((prevColor) => (prevColor === '#ffc800'));
      setBgColorextra((prevColor) => (prevColor === '#ffc800'));
      setBgColorextra1((prevColor) => (prevColor === '#ffc800'));
      subcont1(false);
      // subccom(false);
      commp(false);
      commp1(false);
      subccom5(false);
    }


    setShow(true);
    setShow3(false);
    setShow2(false);
    subcont1(false);
    // subccom(false);
    commp(false);
    subccom5(false);

  };
  const scholar = (end) => {

    if (end == 'scholarend') {
      setBgColoroend1((prevColor) => (prevColor === '#ffc800' ? '#000000' : '#000000'));
      setBgColoroend((prevColor) => (prevColor === '#ffc800' ? '#FFFFFF' : '#FFFFFF'));
      setBgColorani1((prevColor) => (prevColor === '#ffc800'));
      setBgColorani((prevColor) => (prevColor === '#ffc800'));
      setBgColorother((prevColor) => (prevColor === '#ffc800'));
      setBgColorScholarship4((prevColor) => (prevColor === '#ffc800'));
      setBgColorCampus((prevColor) => (prevColor === '#ffc800'));
      setBgColorScholarship2((prevColor) => (prevColor === '#ffc800'));
      setBgColorextra((prevColor) => (prevColor === '#ffc800'));
      setBgColorextra1((prevColor) => (prevColor === '#ffc800'));
      setBgColora((prevColor) => (prevColor === '#ffc800'));
      setBgColorani2((prevColor) => (prevColor === '#ffc800'));
      subcont1(false);
      commp(false);
      commp1(false);
      // subccom(false);
      subccom5(false);
     
    }
    if (end == 'endschol') {
      setBgColorani1((prevColor) => (prevColor === '#ffc800' ? '#000000' : '#000000'));
      setBgColorani((prevColor) => (prevColor === '#ffc800' ? '#FFFFFF' : '#FFFFFF'));
      setBgColoroend1((prevColor) => (prevColor === '#ffc800'));
      setBgColoroend((prevColor) => (prevColor === '#ffc800'));
      setBgColorextra((prevColor) => (prevColor === '#ffc800'));
      setBgColorextra1((prevColor) => (prevColor === '#ffc800'));
      setBgColora((prevColor) => (prevColor === '#ffc800'));
      setBgColorani2((prevColor) => (prevColor === '#ffc800'));
      subcont1(false);
      // subccom(false);
      commp(false);
      commp1(false);
      subccom5(false);

    }
    if (end == 'endschl') {
      setBgColora((prevColor) => (prevColor === '#ffc800' ? '#000000' : '#000000'));
      setBgColorani2((prevColor) => (prevColor === '#ffc800' ? '#FFFFFF' : '#FFFFFF'));
      setBgColorani1((prevColor) => (prevColor === '#ffc800'));
      setBgColorani((prevColor) => (prevColor === '#ffc800'));
      setBgColoroend1((prevColor) => (prevColor === '#ffc800'));
      setBgColoroend((prevColor) => (prevColor === '#ffc800'));
      setBgColorextra((prevColor) => (prevColor === '#ffc800'));
      setBgColorextra1((prevColor) => (prevColor === '#ffc800'));
      subcont1(false);
      subccom5(false);
      commp(false);
      commp1(false);
    }
    setShow3(true);
    setShow(false);
    subcont1(false);
    // subccom(false);
    commp(false);
    commp1(false);
    subccom5(false);
  };
  const scholar1 = (cd) => {
    if (cd == 'ok') {
      setBgColornew((prevColor) => (prevColor === '#ffc800' ? '#FFFFFF' : '#FFFFFF'));
      setBgColornew1((prevColor) => (prevColor === '#ffc800' ? '#000000' : '#000000'));
      setBgColorother((prevColor) => (prevColor === '#ffc800'));
      setBgColorScholarship4((prevColor) => (prevColor === '#ffc800'));
      setBgColorCampus((prevColor) => (prevColor === '#ffc800'));
      setBgColorScholarship2((prevColor) => (prevColor === '#ffc800'));
      setBgColoroend1((prevColor) => (prevColor === '#ffc800'));
      setBgColoroend((prevColor) => (prevColor === '#ffc800'));
      setBgColorani1((prevColor) => (prevColor === '#ffc800'));
      setBgColorani((prevColor) => (prevColor === '#ffc800'));
      setBgColorextra((prevColor) => (prevColor === '#ffc800'));
      setBgColorextra1((prevColor) => (prevColor === '#ffc800'));
      setShow(false);
      setShow2(true);
      setShow3(false);
      subcont1(false);
      // subccom(false);
      commp(false);
      commp1(false);
      subccom5(false);
    }
    if (cd == "box") {
      setBgColorCampus((prevColor) => (prevColor === '#ffc800' ? '#FFFFFF' : '#FFFFFF'));
      setBgColorScholarship2((prevColor) => (prevColor === '#ffc800' ? '#000000' : '#000000'));
      setBgColorother((prevColor) => (prevColor === '#ffc800'));
      setBgColorScholarship4((prevColor) => (prevColor === '#ffc800'));
      setBgColornew((prevColor) => (prevColor === '#ffc800'));
      setBgColornew1((prevColor) => (prevColor === '#ffc800'));
      setBgColoroend1((prevColor) => (prevColor === '#ffc800'));
      setBgColoroend((prevColor) => (prevColor === '#ffc800'));
      setBgColorani1((prevColor) => (prevColor === '#ffc800'));
      setBgColorani((prevColor) => (prevColor === '#ffc800'));
      setBgColorextra((prevColor) => (prevColor === '#ffc800'));
      setBgColorextra1((prevColor) => (prevColor === '#ffc800'));
      setShow2(false);
      setShow(false);
      setShow3(true);
      subcont1(false);
      // subccom(false);
      commp(false);
      commp1(false);
      subccom5(false);
    }
    commp(false);
    commp1(false);
  };
  const subcont = (ex) => {
    if (ex == 'sub') {
      setBgColorextra((prevColor) => (prevColor === '#ffc800' ? '#FFFFFF' : '#FFFFFF'));
      setBgColorextra1((prevColor) => (prevColor === '#ffc800' ? '#000000' : '#000000'));
      setBgColorother((prevColor) => (prevColor === '#ffc800'));
      setBgColorScholarship4((prevColor) => (prevColor === '#ffc800'));
      setBgColornew((prevColor) => (prevColor === '#ffc800'));
      setBgColornew1((prevColor) => (prevColor === '#ffc800'));
      setBgColoroend1((prevColor) => (prevColor === '#ffc800'));
      setBgColoroend((prevColor) => (prevColor === '#ffc800'));
      setBgColorani1((prevColor) => (prevColor === '#ffc800'));
      setBgColorani((prevColor) => (prevColor === '#ffc800'));
      setBgColorCampus((prevColor) => (prevColor === '#ffc800'));
      setBgColorScholarship2((prevColor) => (prevColor === '#ffc800'));
      subcont1(true);
      setShow(false);
      setShow3(false);
      setShow2(false);
      subccom5(false);
      // subccom(false);
      commp(false);
      commp1(false);
    }

  }
  const upcoming = (up) => {
    if (up == 'upcom') {
      setBgColorupcoming((prevColor) => (prevColor === '#ffc800' ? '#FFFFFF' : '#FFFFFF'));
      setBgColorupcoming1((prevColor) => (prevColor === '#ffc800' ? '#000000' : '#000000'));

    }
  }
  const handleButtonClick = () => {
    changeColor('other');
    getProjByCategory('Other Areas');

  };

  const handleButtonClick1 = () => {
    scholar1('box');

    setBgColorCampus((prevColor) => (prevColor === '#ffc800' ? '#FFFFFF' : '#FFFFFF'));
    setBgColorScholarship2((prevColor) => (prevColor === '#ffc800' ? '#000000' : '#000000'));
    setBgColornew1((prevColor) => (prevColor === '#ffc800'));
    setBgColornew((prevColor) => (prevColor === '#ffc800'));
    setBgColorother((prevColor) => (prevColor === '#ffc800'));
    setBgColorScholarship4((prevColor) => (prevColor === '#ffc800'));
    setBgColorextra((prevColor) => (prevColor === '#ffc800'));
    setBgColorextra1((prevColor) => (prevColor === '#ffc800'));
    // subccom(false);
    subcont1(false);
    setShow(false);
    setShow3(false);
    setShow2(false);
    subccom5(true);
    commp(false);
    commp1(false);

  };
  const showcomp = (com) => {

    if (com == 'scholarend1') {
      setBgColoroend1((prevColor) => (prevColor === '#ffc800' ? '#000000' : '#000000'));
      setBgColoroend((prevColor) => (prevColor === '#ffc800' ? '#FFFFFF' : '#FFFFFF'));
      setBgColorani1((prevColor) => (prevColor === '#ffc800'));
      setBgColorani((prevColor) => (prevColor === '#ffc800'));
      setBgColorother((prevColor) => (prevColor === '#ffc800'));
      setBgColorScholarship4((prevColor) => (prevColor === '#ffc800'));
      setBgColorCampus((prevColor) => (prevColor === '#ffc800'));
      setBgColorScholarship2((prevColor) => (prevColor === '#ffc800'));
      setBgColorextra((prevColor) => (prevColor === '#ffc800'));
      setBgColorextra1((prevColor) => (prevColor === '#ffc800'));
      setBgColora((prevColor) => (prevColor === '#ffc800'));
      setBgColorani2((prevColor) => (prevColor === '#ffc800'));
      subcont1(false);
      // subccom(false);
      setShow(false);
      setShow3(false);
      setShow2(false);
      commp1(false);

      commp(true);
    }
    if (com == 'endschol1') {
      setBgColorani1((prevColor) => (prevColor === '#ffc800' ? '#000000' : '#000000'));
      setBgColorani((prevColor) => (prevColor === '#ffc800' ? '#FFFFFF' : '#FFFFFF'));
      setBgColoroend1((prevColor) => (prevColor === '#ffc800'));
      setBgColoroend((prevColor) => (prevColor === '#ffc800'));
      setBgColorextra((prevColor) => (prevColor === '#ffc800'));
      setBgColorextra1((prevColor) => (prevColor === '#ffc800'));
      setBgColora((prevColor) => (prevColor === '#ffc800'));
      setBgColorani2((prevColor) => (prevColor === '#ffc800'));
      subcont1(false);
      // subccom(false);
      subcont1(false);
      subccom(false);
      setShow(false);
      setShow3(false);
      setShow2(false);
      commp(false);
      commp1(true);

    }
    // commp(true);
  }

  return (
    <div className="main-container mt-5">

      <div className="newClassNam  container mt-5" >
        <div className="row">
          <div className="col-md-3  justify-content-start">
            <button className="text-center" onClick={() => scholar1('ok')} style={{ background: BgColornew, color: rednew, fontFamily: 'arial' }}>Scholarship </button>
            <button className="buttonsty" type="button" onClick={() => scholar1('ok')}>
              <img src="Scholarship.png" alt="Scholarship" className="img-fluid anotherClass" style={{ width: '100%', height: 'auto', border: '1px solid #ddd', transition: 'box-shadow 0.3s ease-in-out' }} />

            </button>

          </div>

          <div className="col-md-3">
            <button className="text-center" onClick={() => handleButtonClick1()} style={{ background: bgColorCampus, color: redcam }}>
              Campus Development
            </button>
            <button className="buttonsty" type="button" onClick={() => handleButtonClick1()}>
              <img
                src="TT.JPG"
                alt="Scholarship 2"
                className="img-fluid anotherClass"
                style={{ width: '100%', height: 'auto', border: '1px solid #ddd', transition: 'box-shadow 0.3s ease-in-out' }}
              />
            </button>
          </div>
          <div className="col-md-3">
            <button className="text-center" onClick={() => handleButtonClick()} style={{ background: bgColorother, color: redother }}>
              Other Areas
            </button>

            <button className="buttonsty" type="button" onClick={() => handleButtonClick()}>
              <img src="Masters.png" alt="Scholarship 4" className="img-fluid anotherClass" style={{ width: '100%', height: '80%', border: '1px solid #ddd', transition: 'box-shadow 0.3s ease-in-out' }} onClick={() => getProjByCategory("Other Areas")} />
            </button>
          </div>

          <div className="col-md-3">
          <Link to="/adddo">
          
        
            <button className="text-center" onClick={() => subcont("sub")} style={{ background: reddonex, color: reddonex1 }}>Batch Gifts & Chhota Hazri</button>
            <button className="buttonsty" type="button" onClick={() => subcont("sub")}>
              <img src="batch.jpg" alt="Scholarship 5" className="img-fluid anotherClass" style={{ width: '100%', height: 'auto', border: '1px solid #ddd', transition: 'box-shadow 0.3s ease-in-out' }} />
            </button>
            </Link>

          </div>


        </div>
      </div>
      {
        show2 &&
        <div className="container mt-12 ">
          <div className="row">
            <div className="col-md-3 mb-4 suuucon">
              <button className="buttstyl1" type="button" onClick={() => scholar('scholarend')}>
                <button className="buttstyl"
                  onClick={() => {
                    getProjBy('Scholarship Endowments,Endowments')
                    setStatus('endowments')
                  
                  }}
                  style={{ background: bgColorotherend, color: bgredend, width: "250px" }}>Endowments</button>
              </button>
            </div>
            {/* <div className="col-md-3 mb-4 suuucon">
               <Link to="/endw">
               <button className="buttstyl1" type="button" onClick={() => scholar('scholarend')}>
  <button className="buttstyl"
    onClick={() => getProjBy('Scholarship Endowments,Endowments')}
   style={{ background: bgColorotherend, color: bgredend,width:"250px" }}>Endowments</button>
  </button>

    
     </Link>
    
           
               </div> */}

            <div className="col-md-3 mb-4 mr-3 suuucon">
              <button className="buttstyl1" type="button" onClick={() => scholar('endschol')}>
                <button className="buttstyl"
                  onClick={() => {
                    getProjBy('Scholarship Annuities')
                    setStatus('annuities')
                  }}
                  style={{ background: bgColoroani, color: bgColoroani1, width: "250px" }}>Annuities</button>
              </button>
            </div>
          </div>

        </div>
      }
      {
        show5 &&
        <div className="container mt-12 ">
          <div className="row">

            <div className="col-md-3 mb-4 suuucon">
              <button className="buttstyl2" type="button" onClick={() => showcomp('scholarend1')}>
                <button className="buttstyl"
                  onClick={() => getProjBy('Classroom,Infrastructure')}
                  style={{ background: bgColorotherend, color: bgredend, width: "250px" }}>Infrastructure</button>
              </button>
            </div>

            <div className="col-md-3 mb-4  suuucon">
              <button className="buttstyl2" type="button" onClick={() => showcomp('endschol1')}>
                <button className="buttstyl"
                  onClick={() => getProjBy('Sports Facilities')}
                  
                  
                  style={{ background: bgColoroani, color: bgColoroani1, width: "250px" }}>Sports Facilities</button>
              </button>
            </div>
          </div>

        </div>
      }
     
  {
                    show &&
                    <div className="main-card-container">
                    <Grid container spacing={2.5}>
                      {projList !== undefined &&
                        projList.map((proj1) => (
                          <Grid
                            item
                            md={3}
                            xs={12}
                            sm={6}
                            className="sub-container"
                            key={proj1.id}
                          >
                            <Card>
                              <div>
                                <div
                                  className="img"
                                  onClick={() => {
                                    history(`/donation`, {
                                      state: { id: proj1.id },
                                    });
                                  }}
                                >
                                  <div>
                                    <img
                                      src={proj1.Project_Image}
                                      alt="img"
                                      className="image-size"
                                      style={{
                                        width: '100%',
                                        height: '40vh',
                                        objectFit: 'fill',
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="text">
                                  <p className="tag">{/* <span>{proj1.Project_Category}</span> */}</p>
                                  <h3 className="head3">{proj1.Name}</h3>
                  
                                  <div className="text-right">
                                    <span
                                      className="read-more-line"
                                      onClick={() => {
                                        history("/donation", {
                                          state: { id: proj1.id },
                                        });
                                      }}
                                    >
                                      <div className="line"></div>
                                      <span>Read More</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          </Grid>
                        ))}
                  
                      <Grid item md={3} xs={12} sm={6} className="sub-container">
                        <Card>
                          <Link to="/leg">
                          <div>
                            <img
                              src="legacy.jpg"
                              alt="img"
                              className="image-size"
                              style={{ width: '100%', height: '40vh', objectFit: 'cover' }}
                            />
                          </div>
                          <div className="text">
                            <h3 className="head3">Legacy</h3>
                            <div className="text-right">
                              <span className="read-more-line">
                                <div className="line"></div>
                                <span>Read More</span>
                              </span>
                            </div>
                          </div>
                          </Link>
                        </Card>
                      </Grid>
                  
                      <Grid item md={3} xs={12} sm={6} className="sub-container">
                        <Card>
                          <Link to="/upcoming">
                          <div>
                            <img
                              src="csr.webp"
                              alt="img"
                              className="image-size"
                              style={{ width: '100%', height: '40vh', objectFit: 'cover' }}
                            />
                          </div>
                          <div className="text">
                            <h3 className="head3">CSR Donations</h3>
                            <div className="text-right">
                              <span className="read-more-line">
                                <div className="line"></div>
                                <span>Read More</span>
                              </span>
                            </div>
                          </div>
                          </Link>
                        </Card>
                      </Grid>
                  
                      <Grid item md={3} xs={12} sm={6} className="sub-container">
                        <Card>
                          <Link to="/csr">
                          <div>
                            <img
                              src="Upcoming.jpg"
                              alt="img"
                              className="image-size"
                              style={{ width: '100%', height: '40vh', objectFit: 'cover' }}
                            />
                          </div>
                          <div className="text">
                            <h3 className="head3">Upcoming Projects</h3>
                            <div className="text-right">
                              <span className="read-more-line">
                                <div className="line"></div>
                                <span>Read More</span>
                              </span>
                            </div>
                          </div>
                          </Link>
                        </Card>
                      </Grid>
                  
                      <Grid item md={3} xs={12} sm={6} className="sub-container">
                        <Card>
                        <a href="https://www.doonschool.com/auditorium/index.php">
                          <div>
                         
                            <img
                              src="auditorium.JPG"
                              alt="img"
                              className="image-size"
                              style={{ width: '100%', height: '40vh', objectFit: 'cover' }}
                            />
                          </div>
                          <div className="text">
                            <h3 className="head3">Auditorium Seats </h3>
                            <div className="text-right">
                              <span className="read-more-line">
                                <div className="line"></div>
                                <span>Read More</span>
                              </span>
                            </div>
                          </div>
                          </a>
                        </Card>
                      </Grid>
                    </Grid>
                  </div>
                  
}
   
      <div className="listcontainer1" >
        {
          projList1 !== undefined &&
          processList(projList1, 1, status).map((proj2) => {
            return (
              <div className="column" key={proj2.id}>
                {
                  show3 &&
                  <ul>
                    <h3 className=" tilelist1">
                      <u className='ccmmleft' onClick={() => {
                        historycom("/donation", {
                          state: { id: proj2.id },
                        });
                      }}>{(proj2.Name)} {proj2 && proj2.Total_Amount
                        ? `- Rs ${proj2.Total_Amount.toLocaleString('en-IN')}`
                        : ''}</u>
                    </h3>
                    <div className="text-right">
                      <span
                        className="read-more-line"
                        onClick={() => {
                          historycom("/donation", {
                            state: { id: proj2.id },
                          });
                        }}
                      >
                      </span>

                    </div>

                  </ul>
                }
              </div>
            );
          })
        }
      </div>
      <div className="listcontainer63" >
        {
          projList1 !== undefined &&
          processList(projList1, 2, status).map((proj2) => {
            return (
              <div className="column" key={proj2.id}>
                {
                  show3 &&
                  <ul>
                    <h3 className=" tilelist1">
                      <u className='ccmmleft' onClick={() => {
                        historycom("/donation", {
                          state: { id: proj2.id },
                        });
                      }}>{(proj2.Name)}  {proj2 && proj2.Total_Amount
                        ? `- Rs ${proj2.Total_Amount.toLocaleString('en-IN')}`
                        : ''}</u>
                    </h3>
                    <div className="text-right">
                      <span
                        className="read-more-line"
                        onClick={() => {
                          historycom("/donation", {
                            state: { id: proj2.id },
                          });
                        }}
                      >
                      </span>

                    </div>

                  </ul>
                }
              </div>
            );
          })
        }
      </div>
      <div className="listcontainer26" >
        {
          projList1 !== undefined &&
          projList1.map((proj2) => {
            return (
              <div className="column" key={proj2.id}>
                {
                  comm &&
                  <ul>
                    <h3 className=" tilelist1">

                      <img
                        src={proj2.Project_Image}
                        alt="img"
                        className="image-size anotherClass" style={{ width: '20%', height: '10vh', objectFit: 'fill' }}
                      />

                      <u className='ccmmleft' onClick={() => {
                        historycom("/donation", {
                          state: { id: proj2.id },
                        });
                      }}>{(proj2.Name)} {proj2 && proj2.Total_Amount
                        ? ` - Rs ${proj2.Total_Amount.toLocaleString('en-IN')}`
                        : ''}</u>
                    </h3>
                    <div className="text-right">
                      <span
                        className="read-more-line"
                        onClick={() => {
                          historycom("/donation", {
                            state: { id: proj2.id },
                          });
                        }}
                      >
                      </span>

                    </div>

                  </ul>
                }
              </div>
            );
          })
        }
      </div>
      <div className="listcontainer26" >

        {
          projList1 !== undefined &&
          projList1.map((proj2) => {
            return (
              <div className="column" key={proj2.id}>
                {
                  comm1 &&
                  <ul>
                    <h3 className=" tilelist1">
                      <img
                        src={proj2.Project_Image}
                        alt="img"
                        className="image-size anotherClass" style={{ width: '20%', height: '10vh', objectFit: 'fill' }}
                      />
                      <u className='ccmmleft' onClick={() => {
                        historycom("/donation", {
                          state: { id: proj2.id },
                        });
                      }}>{(proj2.Name)} {proj2 && proj2.Total_Amount
                        ? `- Rs ${proj2.Total_Amount.toLocaleString('en-IN')}`
                        : ''}</u>
                    </h3>
                    <div className="text-right">
                      <span
                        className="read-more-line"
                        onClick={() => {
                          historycom("/donation", {
                            state: { id: proj2.id },
                          });
                        }}
                      >
                      </span>

                    </div>

                  </ul>
                }
              </div>
            );
          })
        }
      </div>
      
      
    </div>
  );


};

export default TabContainer;
